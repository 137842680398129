<template>
  <div class="public">
    <div class="container HomeSupervise">
      <!-- 四色码 -->
      <div class="idiot-one">
        <div class="box" v-for="item in forCodeData" :key="item.type">
          <div class="box-top">
            <div>
              {{
                item.type == 1
                  ? "营业门店:"
                  : item.type == 2
                  ? "从业人员:"
                  : "消费人员:"
              }}
            </div>
            <div>{{ item.xfrq }} {{ item.xfrqcom }}</div>
          </div>
          <div class="box-bottom">
            <Piecontract
              :chartData="{
                seriesData: item.seriesData,
              }"
            ></Piecontract>
            <div class="pietitle">
              <div
                class="pietitlechild"
                v-for="(item, ind) in item.seriesData"
                :key="ind"
              >
                <div
                  class="pietitlechildurl"
                  :style="{ background: colors[ind] }"
                ></div>
                <div class="pietitlechildval">
                  {{ item.name }} {{ item.value }}%
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 营业门店分布情况 地图 -->
      <div class="idiot-two echarts pie-box">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span>疫情防控区域分布图</span>
          </div>
        </div>

        <div class="map-charts">
          <Baidumap
            v-if="regionType >= 3 && areaName"
            v-loading="showbmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :flag="flag"
            :cityName="cityName"
            :areaName="areaName"
            :data="baidumapOptions"
          />
          <Pmap
            v-else
            v-loading="showmap"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :regionName="regionName"
            :cityName="cityName"
            :flag="flag"
            :mapOptions="mapOptions"
          ></Pmap>
        </div>
      </div>
      <!-- 消费人群新增变化趋势 柱状图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                tabPosition2 == 1
                  ? "营业门店"
                  : tabPosition2 == 2
                  ? "从业人员"
                  : "消费人员"
              }}新增变化趋势</span
            >
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal2"></KcSelect>
        </div>
        <div class="charts">
          <LineChart
            :chartData="barChartData"
            v-loading="barChart1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :legendData="['异常防疫门店', '正常防疫门店']"
            :units="['', '', '', '', '']"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <Tab :optionData="optionData" @popupswitch="popupVal"></Tab>
      </div>
      <!-- 营业门店累计变化趋势 柱图 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                tabPosition3 == 1
                  ? "营业门店"
                  : tabPosition3 == 2
                  ? "从业人员"
                  : "消费人员"
              }}累计变化趋势</span
            >
          </div>
          <KcSelect left="0" top="18" @popupVal="popupVal4"></KcSelect>
        </div>
        <div class="charts">
          <!-- <BarChart
            :chartData="barChartDatatwo"
            :legendData="['异常防疫门店', '正常防疫门店']"
          ></BarChart> -->
          <LineChart
            v-loading="barChart2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            :chartData="barChartDatatwo"
            :legendData="['异常防疫门店', '正常防疫门店']"
            :units="['', '', '', '', '']"
            :yAxis="['']"
            :yAxisIndex="['0']"
          ></LineChart>
        </div>
        <Tab :optionData="optionData" @popupswitch="popupVal3"></Tab>
      </div>
      <!-- 营业门店按城市/地区统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                tabPosition4 == 1
                  ? "营业门店"
                  : tabPosition4 == 2
                  ? "从业人员"
                  : "消费人员"
              }}区域分布数量统计</span
            >
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="optionData"
            right="0"
            top="-35"
            @popupVal="popupVal5"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll"
            v-loading="loading1"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            :row-key="rowKey"
            lazy
            :load="loadNextNode1"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort"
            :header-cell-style="headClass"
          >
            <el-table-column
              prop="regionName"
              :key="0"
              label="地区"
              :width="0.18 * $bodyWidth"
              fixed
            >
            </el-table-column>
            <template v-if="tabPosition4 == 1">
              <el-table-column
                prop="shopNum"
                :key="1"
                :width="0.25 * $bodyWidth"
                label="营业门店"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.shopNum }}</span
                  ><i
                    :class="
                      scope.row.shopNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.shopNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.shopNumTit > 0
                        ? "+" + scope.row.shopNumTit
                        : scope.row.shopNumTit < 0
                        ? "" + scope.row.shopNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="normalShopNum"
                :key="2"
                :width="0.38 * $bodyWidth"
                label="正常营业门店"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.normalShopNum }}</span
                  ><i
                    :class="
                      scope.row.normalShopNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.normalShopNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.normalShopNumTit > 0
                        ? "+" + scope.row.normalShopNumTit
                        : scope.row.normalShopNumTit < 0
                        ? "" + scope.row.normalShopNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="abnormalShopNum"
                :key="3"
                :width="0.38 * $bodyWidth"
                label="异常营业门店"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.abnormalShopNum }}</span
                  ><i
                    :class="
                      scope.row.abnormalShopNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.abnormalShopNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.abnormalShopNumTit > 0
                        ? "+" + scope.row.abnormalShopNumTit
                        : scope.row.abnormalShopNumTit < 0
                        ? "" + scope.row.abnormalShopNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition4 == 2">
              <el-table-column
                prop="employeeNum"
                :key="1"
                :width="0.25 * $bodyWidth"
                label="从业人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.employeeNum }}</span
                  ><i
                    :class="
                      scope.row.employeeNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.employeeNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.employeeNumTit > 0
                        ? "+" + scope.row.employeeNumTit
                        : scope.row.employeeNumTit < 0
                        ? "" + scope.row.employeeNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="normalEmployeeNum"
                :key="2"
                :width="0.38 * $bodyWidth"
                label="正常从业人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.normalEmployeeNum }}</span
                  ><i
                    :class="
                      scope.row.normalEmployeeNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.normalEmployeeNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.normalEmployeeNumTit > 0
                        ? "+" + scope.row.normalEmployeeNumTit
                        : scope.row.normalEmployeeNumTit < 0
                        ? "" + scope.row.normalEmployeeNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="abnormalEmployeeNum"
                :key="3"
                :width="0.38 * $bodyWidth"
                label="异常从业人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{
                    scope.row.abnormalEmployeeNum
                  }}</span
                  ><i
                    :class="
                      scope.row.abnormalEmployeeNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.abnormalEmployeeNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.abnormalEmployeeNumTit > 0
                        ? "+" + scope.row.abnormalEmployeeNumTit
                        : scope.row.abnormalEmployeeNumTit < 0
                        ? "" + scope.row.abnormalEmployeeNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition4 == 3">
              <el-table-column
                prop="consumersNum"
                :key="1"
                :width="0.25 * $bodyWidth"
                label="消费人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.consumersNum }}</span
                  ><i
                    :class="
                      scope.row.consumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.consumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.consumersNumTit > 0
                        ? "+" + scope.row.consumersNumTit
                        : scope.row.consumersNumTit < 0
                        ? "" + scope.row.consumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="normalConsumerNum"
                :key="2"
                :width="0.38 * $bodyWidth"
                label="正常消费人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.normalConsumerNum }}</span
                  ><i
                    :class="
                      scope.row.normalConsumerNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.normalConsumerNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.normalConsumerNumTit > 0
                        ? "+" + scope.row.normalConsumerNumTit
                        : scope.row.normalConsumerNumTit < 0
                        ? "" + scope.row.normalConsumerNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="abnormalConsumerNum"
                :key="3"
                :width="0.38 * $bodyWidth"
                label="异常消费人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{
                    scope.row.abnormalConsumerNum
                  }}</span
                  ><i
                    :class="
                      scope.row.abnormalConsumerNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.abnormalConsumerNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.abnormalConsumerNumTit > 0
                        ? "+" + scope.row.abnormalConsumerNumTit
                        : scope.row.abnormalConsumerNumTit < 0
                        ? "" + scope.row.abnormalConsumerNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData1.length > 10">
            <el-button type="text" @click="showAll = !showAll"
              >{{ showAll ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
      <!-- 消费人群按领域/行业统计 表格 -->
      <div class="idiot-two echarts">
        <div class="echarts-box">
          <div class="echarts-tit">
            <span
              >{{
                tabPosition5 == 1
                  ? "营业门店"
                  : tabPosition5 == 2
                  ? "从业人员"
                  : "消费人员"
              }}领域/行业分布数量统计</span
            >
          </div>
          <KcSelect
            isBlue
            keyLabel="haripinType"
            :optionData="optionData"
            right="0"
            top="-35"
            @popupVal="popupVal6"
          ></KcSelect>
        </div>
        <div class="element-tab">
          <el-table
            :data="tableDataAll2"
            v-loading="loading2"
            element-loading-text="加载中..."
            element-loading-spinner="el-icon-loading"
            style="width: 100%"
            row-key="industryId"
            lazy
            :load="loadNextNode2"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            @sort-change="changeSort2"
            :header-cell-style="{ textAlign: 'center' }"
          >
            <el-table-column
              prop="industryName"
              :key="0"
              label="领域/行业"
              sortable
              :width="0.32 * $bodyWidth"
              fixed
            >
            </el-table-column>

            <template v-if="tabPosition5 == 1">
              <el-table-column
                prop="shopNum"
                :key="1"
                label="营业门店"
                :width="0.32 * $bodyWidth"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.shopNum }}</span
                  ><i
                    :class="
                      scope.row.shopNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.shopNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.shopNumTit > 0
                        ? "+" + scope.row.shopNumTit
                        : scope.row.shopNumTit < 0
                        ? "" + scope.row.shopNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="normalShopNum"
                :key="2"
                :width="0.38 * $bodyWidth"
                label="正常营业门店"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.normalShopNum }}</span
                  ><i
                    :class="
                      scope.row.normalShopNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.normalShopNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.normalShopNumTit > 0
                        ? "+" + scope.row.normalShopNumTit
                        : scope.row.normalShopNumTit < 0
                        ? "" + scope.row.normalShopNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="abnormalShopNum"
                :key="3"
                :width="0.38 * $bodyWidth"
                label="异常营业门店"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.abnormalShopNum }}</span
                  ><i
                    :class="
                      scope.row.abnormalShopNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.abnormalShopNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.abnormalShopNumTit > 0
                        ? "+" + scope.row.abnormalShopNumTit
                        : scope.row.abnormalShopNumTit < 0
                        ? "" + scope.row.abnormalShopNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
            <template v-if="tabPosition5 == 2">
              <el-table-column
                prop="employeeNum"
                :key="1"
                :width="0.25 * $bodyWidth"
                label="从业人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.employeeNum }}</span
                  ><i
                    :class="
                      scope.row.employeeNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.employeeNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.employeeNumTit > 0
                        ? "+" + scope.row.employeeNumTit
                        : scope.row.employeeNumTit < 0
                        ? "" + scope.row.employeeNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="normalEmployeeNum"
                :key="2"
                :width="0.38 * $bodyWidth"
                label="正常从业人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.normalEmployeeNum }}</span
                  ><i
                    :class="
                      scope.row.normalEmployeeNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.normalEmployeeNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.normalEmployeeNumTit > 0
                        ? "+" + scope.row.normalEmployeeNumTit
                        : scope.row.normalEmployeeNumTit < 0
                        ? "" + scope.row.normalEmployeeNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="abnormalEmployeeNum"
                :key="3"
                :width="0.38 * $bodyWidth"
                label="异常从业人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{
                    scope.row.abnormalEmployeeNum
                  }}</span
                  ><i
                    :class="
                      scope.row.abnormalEmployeeNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.abnormalEmployeeNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.abnormalEmployeeNumTit > 0
                        ? "+" + scope.row.abnormalEmployeeNumTit
                        : scope.row.abnormalEmployeeNumTit < 0
                        ? "" + scope.row.abnormalEmployeeNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>

            <template v-if="tabPosition5 == 3">
              <el-table-column
                prop="consumersNum"
                :key="1"
                :width="0.25 * $bodyWidth"
                label="消费人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.consumersNum }}</span
                  ><i
                    :class="
                      scope.row.consumersNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.consumersNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.consumersNumTit > 0
                        ? "+" + scope.row.consumersNumTit
                        : scope.row.consumersNumTit < 0
                        ? "" + scope.row.consumersNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="normalConsumerNum"
                :key="2"
                :width="0.38 * $bodyWidth"
                label="正常消费人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{ scope.row.normalConsumerNum }}</span
                  ><i
                    :class="
                      scope.row.normalConsumerNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.normalConsumerNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.normalConsumerNumTit > 0
                        ? "+" + scope.row.normalConsumerNumTit
                        : scope.row.normalConsumerNumTit < 0
                        ? "" + scope.row.normalConsumerNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="abnormalConsumerNum"
                :key="3"
                :width="0.38 * $bodyWidth"
                label="异常消费人员"
                sortable
              >
                <template slot-scope="scope">
                  <span class="num-box">{{
                    scope.row.abnormalConsumerNum
                  }}</span
                  ><i
                    :class="
                      scope.row.abnormalConsumerNumTit > 0
                        ? 'num-badge-blue'
                        : scope.row.abnormalConsumerNumTit < 0
                        ? 'num-badge-orag'
                        : 'none'
                    "
                    >{{
                      scope.row.abnormalConsumerNumTit > 0
                        ? "+" + scope.row.abnormalConsumerNumTit
                        : scope.row.abnormalConsumerNumTit < 0
                        ? "" + scope.row.abnormalConsumerNumTit
                        : ""
                    }}</i
                  >
                </template>
              </el-table-column>
            </template>
          </el-table>
          <div class="page-btn" v-if="tableData2.length > 10">
            <el-button type="text" @click="showAll2 = !showAll2"
              >{{ showAll2 ? "收起全部" : "展开全部"
              }}<i
                class="el-icon-d-arrow-left el-icon--right"
                :class="showAll2 ? 'rotate-top' : 'rotate-bottom'"
              ></i
            ></el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LineChart from "@/components/charts/line2"; // 四码折线图
import PieChart from "@/components/charts/PieSupervise"; //饼图
import PieSmallChart from "@/components/charts/pieSmall"; //饼图
import Baidumap from "@/components/baidumap/index.vue";
import BarChart from "@/components/charts/bar";
import Piecontract from "@/components/charts/Pieadvance.vue";
import {
  moneyStatistics,
  busStatistics,
  bankStatistics,
  insuranceBankStatistics,
} from "@/api/homesupervise";
import { cardType } from "@/api/common";
import Tab from "@/components/tab.vue";
import {
  regionalDistributionInfo,
  contractStatistics,
  epidemicPreventionTrend,
  epidemicPreventionCumulative,
  fundSupervisionCityStatistics,
  fundIndustryStatistics,
} from "@/api/homeEpidemicpreventioncontrol.js";
import Pmap from "@/components/charts/map.vue"; //地图

export default {
  name: "HomeSupervise",
  props: {
    regionName: [String],
    areaName: [String],
    cityName: [String],
    flag: [String, Number],
    regionId: [String, Number],
    regionType: [String, Number],
  },
  data() {
    return {
      barChart1: false,
      barChart2: false,
      optionData: [
        { name: "营业门店", haripinType: 1 },
        { name: "从业人员", haripinType: 2 },
        { name: "消费人员", haripinType: 3 },
      ],
      colors: ["#feb837", "#4f77e1"],
      outing: [],
      options: [],
      forCodeData: [],
      typeName: "资金",
      typeName2: "资金",
      typeName3: "资金",
      typeName4: "资金",
      typeName5: "资金",
      tabPosition1: 1,
      tabPosition2: 1,
      tabPosition3: 1,
      tabPosition4: 1,
      tabPosition5: 1,
      tabPosition6: 1,
      isTowPie: false,
      statisticsData: [],
      cunguan: "",
      baoxian: "",
      pieChartData: {
        seriesData: [],
      },
      pieChartData2: {
        seriesData: [],
      },
      barChartData: {
        xAxisData: [
          "2020-10-01",
          "2020-10-02",
          "2020-10-03",
          "2020-10-04",
          "2020-10-05",
          "2020-10-06",
          "2020-10-07",
        ],
        seriesData: [
          {
            name: "异常防疫门店",
            data: [1, 2, 3, 2, 3, 2, 3],
          },
          {
            name: "正常防疫门店",
            data: [1, 2, 3, 2, 3, 2, 3],
          },
        ],
      },
      barChartDatatwo: {
        xAxisData: [
          "2020-10-01",
          "2020-10-02",
          "2020-10-03",
          "2020-10-04",
          "2020-10-05",
          "2020-10-06",
          "2020-10-07",
        ],
        seriesData: [
          {
            name: "异常防疫门店",
            data: [1, 2, 3, 2, 3, 2, 3],
          },
          {
            name: "正常防疫门店",
            data: [1, 2, 3, 2, 3, 2, 3],
          },
        ],
      },
      legendData: ["存管商户发卡金额", "保险商户发卡金额"],
      unitData: ["元", "元"],
      radio1: "1",
      radio2: "1",
      radioData1: [
        {
          name: "近一周",
          value: "1",
        },
        {
          name: "近一月",
          value: "2",
        },
        {
          name: "近半年",
          value: "3",
        },
        {
          name: "全部",
          value: "4",
        },
      ],
      lineChartData: {
        xAxisData: [],
        seriesData: [
          {
            name: "存管商户发卡金额",
            data: [],
          },
          {
            name: "保险商户发卡金额",
            data: [],
          },
        ],
      },
      legendData2: ["存管商户发卡金额", "保险商户发卡金额"],
      unitData2: ["元", "元"],
      showAll: false,
      loading1: false,
      loading2: false,
      tableDataAll: [],
      tableData1: [],
      showAll2: false,
      tableDataAll2: [],
      tableData2: [],
      showAll3: false,
      tableDataAll3: [],
      tableData3: [],
      pieSmallChartData: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      pieSmallChartData2: {
        seriesData: [
          {
            name: "保险商户发卡金额",
            value: "",
          },
        ],
      },
      mapOptions: [],
      baidumapOptions: [],
      showmap: true,
      showbmap: true,
    };
  },
  computed: {
    costRequest() {
      return {
        regionName: this.regionName,
        areaName: this.areaName,
        cityName: this.cityName,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      };
    },
  },
  filters: {
    numFilter(value) {
      // 截取当前数据到小数点后两位
      let realVal = parseFloat(value).toFixed(2);
      return realVal;
    },
  },
  watch: {
    costRequest: {
      handler(val, oldVal) {
        this.intData();
      },
      deep: true, //增加deep 观察对象的子对象变化
    },
    tabPosition1: function (val) {
      //console.log(val)
      //this.isTowPie = false
      this.getBankStatistics();
      this.getInsuranceBankStatistics();
      if (val == 1) {
        this.moneyStatistics();
      } else {
        this.busStatistics();
      }
      this.typeName = val == 1 ? "资金" : "商户";
    },
    radio1: function (val) {
      this.getepidemicPreventionTrend();
    },
    tabPosition2: function (val) {
      this.getepidemicPreventionTrend();
      this.legendData =
        val == 0
          ? ["存管商户发卡金额", "保险商户发卡金额"]
          : ["存管商户数量", "保险商户数量"];
      this.unitData = val == 0 ? ["元", "元"] : ["家", "家"];
      this.typeName2 = val == 0 ? "资金" : "商户";
    },
    radio2: function (val) {
      this.getepidemicPreventionCumulative();
    },
    tabPosition3: function (val) {
      this.getepidemicPreventionCumulative();
      this.legendData2 =
        val == 0
          ? ["存管商户发卡金额", "保险商户发卡金额"]
          : ["存管商户数量", "保险商户数量"];
      this.unitData2 = val == 0 ? ["元", "元"] : ["家", "家"];
      this.typeName3 = val == 0 ? "资金" : "商户";
    },
    tabPosition4: function (val) {
      this.fundSupervisionCityStatistics();
      this.typeName4 = val == 0 ? "资金" : "商户";
    },
    tabPosition5: function (val) {
      this.fundIndustryStatistics();
      this.typeName5 = val == 0 ? "资金" : "商户";
    },
    showAll: function (val) {
      this.showAllData();
    },
    showAll2: function (val) {
      this.showAllData2();
    },
  },
  mounted() {
    this.intData();
  },
  methods: {
    popupVal(val, row) {
      this.tabPosition2 = val.haripinType;
    },
    popupVal2(val) {
      this.radio1 = val;
    },
    popupVal3(val, row) {
      this.tabPosition3 = val.haripinType;
    },
    popupVal4(val) {
      this.radio2 = val;
    },
    popupVal5(val, row) {
      this.tabPosition4 = val;
    },
    popupVal6(val, row) {
      this.tabPosition5 = val;
    },

    // 表头样式设置
    headClass() {
      return "text-align: center;";
    },

    intData() {
      this.cardType();
      this.getMapOptionData();
      this.moneyStatistics();
      this.fundSupervisionCityStatistics();
      this.fundIndustryStatistics();
      this.getBankStatistics();
      this.getInsuranceBankStatistics();
      this.getcontractStatistics();
      this.getepidemicPreventionTrend();
      this.getepidemicPreventionCumulative();
    },
    cardType() {
      cardType().then((res) => {
        this.options = res.data;
      });
    },
    getcontractStatistics() {
      contractStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        console.log(res, 123454234);
        this.forCodeData = [
          {
            seriesData: [
              {
                name: "异常防疫门店",
                value: res.data.abnormalShopRate,
              },
              {
                name: "正常防疫门店",
                value: res.data.normalShopRate,
              },
            ],
            xfrq: res.data.openShopNum,
            xfrqcom: "家",
            type: 1,
          },
          {
            seriesData: [
              {
                name: "健康从业人员",
                value: res.data.normalEmployeeRate,
              },
              {
                name: "有症状从业人员",
                value: res.data.abnormalEmployeeRate,
              },
            ],
            xfrq: res.data.employeeNum,
            xfrqcom: "人",
            type: 2,
          },
          {
            seriesData: [
              {
                name: "健康消费人员",
                value: res.data.normalConsumerRate,
              },
              {
                name: "有症状消费人员",
                value: res.data.abnormalConsumerRate,
              },
            ],
            xfrq: res.data.consumerNum,
            xfrqcom: "人",
            type: 3,
          },
        ];
      });
    },
    getepidemicPreventionCumulative() {
      this.barChart2 = true;
      epidemicPreventionCumulative({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio2,
        infoType: this.tabPosition3,
      }).then((res) => {
        this.barChart2 = false;
        this.barChartDatatwo = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name:
                this.tabPosition3 == 1
                  ? "异常营业门店"
                  : this.tabPosition3 == 2
                  ? "异常从业人员"
                  : "异常消费人员",
              data:
                this.tabPosition3 == 1
                  ? res.data.abnormalShopNum
                  : this.tabPosition3 == 2
                  ? res.data.abnormalEmployeeNum
                  : res.data.abnormalConsumerNum,
            },
            {
              name:
                this.tabPosition3 == 1
                  ? "正常营业门店"
                  : this.tabPosition3 == 2
                  ? "正常从业人员"
                  : "正常消费人员",
              data:
                this.tabPosition3 == 1
                  ? res.data.normalShopNum
                  : this.tabPosition3 == 2
                  ? res.data.normalEmployeeNum
                  : res.data.normalConsumerNum,
            },
          ],
        };
      });
    },
    getMapOptionData() {
      this.showbmap = true;
      this.showmap = true;
      regionalDistributionInfo({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      })
        .then((res) => {
          //this.mapOptions = res.data;
          let data = [];
          res.data.forEach((item) => {
            data.push({
              name: item.regionName,
              value: item.openShopNum,
              normalShopNum: item.normalShopNum,
              abnormalShopNum: item.abnormalShopNum,
            });
          });
          this.mapOptions = data;
          this.showmap = false;
          if (this.regionType >= 3 && this.areaName) {
            this.baidumapOptions = data;
            this.showbmap = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getBankStatistics() {
      let _this = this;
      bankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('应存管发卡银行/商户占比',res)
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData.seriesData = arr;
        });
      });
    },
    getInsuranceBankStatistics() {
      let _this = this;
      insuranceBankStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: _this.tabPosition1,
      }).then((res) => {
        //console.log('保险资金各银行/商户占比',res)
        let arr = [];
        res.data.forEach((item) => {
          if (_this.tabPosition1 == 1) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.money,
                ratio: item.cratio,
                unit: "元",
              });
            }
          } else if (_this.tabPosition1 == 2) {
            if (item.name == "其他") {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
                selected: false,
                itemStyle: {
                  color: "#0000",
                  borderWidth: 0,
                },
                label: { show: false },
                hoverAnimation: false,
              });
            } else {
              arr.push({
                name: item.name,
                value: item.num,
                ratio: item.nratio,
                unit: "家",
              });
            }
          }
          _this.pieChartData2.seriesData = arr;
        });
      });
    },
    toOnePie() {
      if (this.tabPosition1 == 1) {
        this.moneyStatistics();
      } else if (this.tabPosition1 == 2) {
        this.busStatistics();
      }
      this.isTowPie = false;
    },
    moneyStatistics() {
      let _this = this;
      moneyStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('资金占比',res)
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    busStatistics() {
      let _this = this;
      busStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('商户占比',res)
        res.data.forEach((item) => {
          if (item.name.indexOf("存管") != -1) {
            _this.cunguan = item.totalRatio;
          } else if (item.name.indexOf("保险") != -1) {
            _this.baoxian = item.totalRatio;
          }
        });
        _this.statisticsData = res.data;
      });
    },
    getepidemicPreventionTrend() {
      this.barChart1 = true;
      epidemicPreventionTrend({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        type: this.radio1,
        infoType: this.tabPosition2,
      }).then((res) => {
        this.barChart1 = false;
        this.barChartData = {
          xAxisData: res.data.x_axis,
          seriesData: [
            {
              name:
                this.tabPosition2 == 1
                  ? "异常营业门店"
                  : this.tabPosition2 == 2
                  ? "异常从业人员"
                  : "异常消费人员",
              data:
                this.tabPosition2 == 1
                  ? res.data.abnormalShopNum
                  : this.tabPosition2 == 2
                  ? res.data.abnormalEmployeeNum
                  : res.data.abnormalConsumerNum,
            },
            {
              name:
                this.tabPosition2 == 1
                  ? "正常营业门店"
                  : this.tabPosition2 == 2
                  ? "正常从业人员"
                  : "正常消费人员",
              data:
                this.tabPosition2 == 1
                  ? res.data.normalShopNum
                  : this.tabPosition2 == 2
                  ? res.data.normalEmployeeNum
                  : res.data.normalConsumerNum,
            },
          ],
        };
      });
    },

    fundSupervisionCityStatistics() {
      let _this = this;
      this.loading1 = true;
      fundSupervisionCityStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        infoType: this.tabPosition4,
      }).then((res) => {
        //console.log('预付码按城市/地区统计',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        this.loading1 = false;
        this.tableDataAll = [];
        this.$nextTick(() => {
          this.tableData1 = res.data;
          this.tableDataAll = this.tableData1.slice(0, 10);
          this.showAllData();
        });
      });
    },
    showAllData() {
      if (!this.showAll) {
        this.tableDataAll = this.tableData1.slice(0, 10);
      } else {
        this.tableDataAll = this.tableData1;
      }
    },
    rowKey(row) {
      return row.regionName + row.regionId;
    },
    loadNextNode1(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      fundSupervisionCityStatistics({
        regionId: tree.regionId,
        regionType: tree.regionType,
        flag: tree.regionType,
      }).then((res) => {
        //console.log('预付码按城市/地区统计-二级',res)
        // res.data.forEach(item => {
        //   item.hasChildren = true;
        // })
        resolve(res.data);
      });
    },
    fundIndustryStatistics() {
      this.loading2 = true;
      fundIndustryStatistics({
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
        infoType: this.tabPosition5,
        industryId: this.industryId,
      }).then((res) => {
        console.log(
          "预付码按领域/行业统计",
          res,
          this.tabPosition5,
          this.industryId
        );
        res.data.forEach((item) => {
          item.hasChildren = true;
        });
        this.loading2 = false;
        this.tableDataAll2 = [];
        this.$nextTick(() => {
          this.tableData2 = res.data;
          this.tableDataAll2 = this.tableData2.slice(0, 10);
          this.showAllData2();
        });
      });
    },
    showAllData2() {
      if (!this.showAll2) {
        this.tableDataAll2 = this.tableData2.slice(0, 10);
      } else {
        this.tableDataAll2 = this.tableData2;
      }
    },
    loadNextNode2(tree, treeNode, resolve) {
      //console.log(tree, treeNode, resolve)
      fundIndustryStatistics({
        industryId: tree.industryId,
        regionId: this.regionId,
        regionType: this.regionType,
        flag: this.flag,
      }).then((res) => {
        //console.log('预付码按领域/行业统计-二级',res)
        resolve(res.data);
      });
    },
    changeSort(column, prop, order) {
      this.tableDataAll = this.tableData1;
      this.showAll = true;
    },
    changeSort2(column, prop, order) {
      this.tableDataAll2 = this.tableData2;
      this.showAll2 = true;
    },
  },
  components: {
    LineChart,
    PieChart,
    BarChart,
    PieSmallChart,
    Piecontract,
    Pmap,
    Tab,
    Baidumap,
  },
};
</script>

<style lang="scss" scoped >
.HomeSupervise {
  // 四色码
  .idiot-one {
    margin-bottom: 30px;
    background: white;
    .box {
      width: 100%;
      text-align: center;
      position: relative;
      display: flex;
      .box-top {
        width: 40%;
        height: 300px;
        font-size: 30px;
        color: #232a24;
        & > div {
          height: 35px;
          line-height: 30px;
          margin: 0 5px;
        }
        & > div:nth-child(1) {
          margin-top: 90px;
          margin-bottom: 25px;
        }
      }

      .box-bottom {
        width: 60%;
        height: 300px;
        position: relative;
        .pietitle {
          position: absolute;
          bottom: 5px;
          right: 0;
          width: 100%;
          height: 90px;
          .pietitlechild {
            height: 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 21px;
            color: #312d37;
            .pietitlechildurl {
              width: 12px;
              height: 12px;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .box:nth-child(1) {
      border-bottom: 1px dashed #eeeeee;
    }
    .box:nth-child(2) {
      border-bottom: 1px dashed #eeeeee;
    }
  }
  .pie-box {
    // background: url("../assets/imgs/pie-bg3.png") no-repeat;
    // background-size: 100% 100%;
    margin-bottom: 30px;
    .echarts-box {
      position: relative;
      .echarts-tit {
        .txt {
          margin-left: 30px;
          line-height: 40px;
          i {
            font-weight: bold;
          }
        }
      }
    }
    .pie-chart-box {
      .txt {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
        margin-top: 50px;
        span:nth-child(1) {
          display: block;
          font-size: 18px;
        }
        span:nth-child(2) {
          display: block;
          font-size: 30px;
          color: #5bd5f7;
          i {
            font-size: 18px;
            margin-left: 10px;
          }
        }
        &:nth-child(2) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              transparent,
              transparent,
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            0 30 30 30;
          span:nth-child(2) {
            color: #208dfd;
          }
        }
      }
      .pie-charts {
        float: left;
        width: 50%;
        height: 550px;
        box-sizing: border-box;
        background: url("../assets/imgs/pie-bg2.png") no-repeat center center;
        background-size: 55%;
        &:nth-child(3) {
          border-left: 1px solid transparent;
          border-image: linear-gradient(
              #17688d,
              #17688d,
              transparent,
              transparent
            )
            30 30;
        }
      }
    }
  }
  .tab-select-btn {
    position: absolute;
    top: 6px;
    right: 0;
    &.right-size {
      right: 130px;
    }
  }
}
</style>
