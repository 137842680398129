import $api from './api'
import { TIMEOUT, ERR_OK, baseURL, STATUS, local } from './config'

/**
 * 消费人群和消费金额饼图
 */
export const contractStatistics = (params) => {
    return $api.get({
        url: baseURL + '/api/epidemicrPevention/contractStatistics',
        params
    })
}

/**
 * 营业门店区域分布
 */
 export const regionalDistributionInfo = (params) => {
    return $api.get({
        url: baseURL + '/api/epidemicrPevention/regionalShopInfo',
        params
    })
}

/**
 * 疫情防控新增趋势
 */
 export const epidemicPreventionTrend = (params) => {
    return $api.post({
        url: baseURL + '/api/epidemicrPevention/epidemicPreventionTrend',
        params
    })
}

/**
 * 疫情防控累计趋势
 */
 export const epidemicPreventionCumulative = (params) => {
    return $api.post({
        url: baseURL + '/api/epidemicrPevention/epidemicPreventionCumulative',
        params
    })
}

/**
 * 疫情防控按城市/地区统计
 */
 export const fundSupervisionCityStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/epidemicrPevention/cityStatistics',
        params
    })
}

/**
 * 疫情防控按领域/行业统计
 */
 export const fundIndustryStatistics = (params) => {
    return $api.post({
        url: baseURL + '/api/epidemicrPevention/industryStatistics',
        params
    })
}
